@import '../../../../../styles/variaveis';

.modalContainer {
  .fechar {
    top: 15px;
    right: 15px;
    position: absolute;
    z-index: 10;
    width: 22px;
    height: 22px;
    font-weight: bold;
    cursor: pointer;
    fill: $white;
  }

  .modal {
    width: 65vw;
    max-width: 600px;
    padding: 40px 15%;
    position: relative;
    background-color: $grey-dark;
    
    .containerForm {
      border-radius: .4rem;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      width: 100%;
      height: 100%;

      .photoConteiner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .photo {
          width: 226px;
          height: 226px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid $bg-light;
          background-color: $bg;

          .imagem {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid $bg-light;
          }
        }
      }

      .editPhoto {
        margin-bottom: 8px;
        margin-top: 12px;

        label {
          color: $white;
          font-family: $fontPadrao;
        }

        .input {
          display: block;
          width: 100%;
          height: 30px;
          padding: 0.8rem;
          margin-top: 8px;
          border: 0.1px solid $input-grey;
          border-radius: $radius;
          font-family: $fontPadrao;
          background: $input-grey;
          color: $grey-light;
          transition: 0.2s;

          &:focus,
          &:hover {
            outline: none;
            border-color: $grey-dark;
            background: $grey-light;
            box-shadow: 0 0 0 1px $grey-dark;
            color: $input-focus;
          }
        }
      }

      .buttons {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        .excluir {
          padding: 8px 5px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: .1s ease-in-out;
          border-radius: .4rem;
          cursor: pointer;

          .icon {
            size: 20;
          }

          &:hover {
            background-color: $grey-light;
          }
        }
      }
    }
  }
}

@keyframes modalAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
