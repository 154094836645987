@import '../../styles/variaveis';

.containerPai {
  background-color: $bg;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  overflow-y: hidden;

  .wrapperContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 16px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    justify-items: stretch;
    align-items: center;
    display: flex;
    column-gap: 32px;
  }

  .sidebarSmall {
    grid-area: sidebar;
    width: 118px;
    transition: 4s ease-in;
    height: 100%;
    animation: toClose 0.35s ease forwards;
  }

  .sidebar {
    height: 100%;
    transition: 4s ease-in;
    animation: toOpen 0.35s ease forwards;
  }

  .routes {
    height: 100%;
    width: 100%;
  }
}

@keyframes toClose {
  from {
    width: 298px;
  }
  to {
    width: 118px;
  }
}

@keyframes toOpen {
  from {
    width: 118px;
  }
  to {
    width: 298px;
  }
}
