@import '../../styles/_variaveis.scss';

.button {
  cursor: pointer;
  height: 30px;
  padding: 0rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: $radius;
  box-sizing: border-box;
  background: $ocean;
  transition: 0.1s;
  
  .text {
    color: $white;
    font-size: 1rem;
    font-family: $fontPadrao;
    font-weight: 700;
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fea, 0 0 0 3px $light-ocean; /* assim criando uma bordar após a sobra*/
  }

  &:disabled {
    opacity: 0.5;
    cursor: wait;
    box-shadow: none;
  }

  .btnLoading {
    margin-right: 10px;
    height: 1.1rem;
    width: 1rem;
    border: 0.15rem solid transparent;
    border-right-color: #fff;
    border-radius: 50%;
    animation: spinner 0.6s linear infinite;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
