@import '../../styles/_variaveis.scss';

.wrapper {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;

  .input {
    display: block;
    width: 100%;
    height: 30px;
    padding: 0.8rem;
    margin-top: 8px;
    border: 0.1px solid $input-grey;
    border-radius: $radius;
    font-family: $fontPadrao;
    background: $input-grey;
    color: $grey-light;
    transition: 0.2s;

    &:focus,
    &:hover {
      outline: none;
      border-color: $grey-dark;
      background: $grey-light;
      box-shadow: 0 0 0 1px $grey-dark;
      color: $input-focus;
    }
  }

  .error {
    color: #f31;
    font-size: 12px;
    font-weight: 500;
    margin-top: 12px;
  }
}

@media screen and (max-width: 29rem) {
  .input {
    background: $input-focus;
    display: block;
    max-width: 100%;
    padding: 0.8rem;
    border-radius: $radius;
    transition: 0.2s;
    height: 30px;
  }
}
