.title {
  font-size: 32px;
  margin-bottom: 4rem;
}

.wrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;

  .ContainerForm{ 
    background: transparent;
  }
}

// MODAL
.modalContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: none;
  align-content: center;
}

.modalAtivo {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  align-content: center;

  .wrapperModal {
    animation: modalAnimation 0.3s forwards;
  }
}

@keyframes modalAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


// message
.messageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 155px;
  animation: modalAnimation 0.3s forwards;
  
  p{
    padding: 24px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
}


@keyframes modalAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}