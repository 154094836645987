.title {
  font-size: 32px;
  margin-bottom: 4rem;
}

.wrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;

  .ContainerForm{ 
    background: transparent;
  }
}

// message
.messageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 155px;
  animation: modalAnimation 0.3s forwards;
  
  p{
    padding: 24px 30px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
  }
}


@keyframes modalAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}