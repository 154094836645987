@import '../../../styles/variaveis';

.containerPai {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: transparent;
  font-size: 12px;
  color: $white;
  font-family: $fontPadrao;
  border-radius: 4px;
  background-color: $bg-light;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden auto;
    padding: 37px 55px 37px 33px;

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: $grey-textInput;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: $bg-light-scroll;
      -webkit-border-radius: 4px;
    }

    &::-webkit-scrollbar-button:start:decrement {
      display: none;
    }
  }
}


