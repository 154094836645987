@import '../../../../styles/variaveis';

// message
.messageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 155px;
  animation: modalAnimation 0.3s forwards;

  p {
    padding: 24px 30px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
  }
}
.wrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;

  .photoConteiner {
    display: flex;
    flex-direction: row;
    padding-bottom: 28px;

    .photo {
      width: 158px;
      height: 158px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $bg-light;
      background-color: $bg;

      .imagem {
        max-width: 100%;
        min-height: 100%;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .editPhoto {
      cursor: pointer;
      align-self: flex-end;
    }
  }

  .ContainerForm {
    background: transparent;
  }
}

.mensagem {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 40px;
  margin: 10px;
  cursor: pointer;

  .icon {
    font-size: 30px;
  }

  .orientaceos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 10px;
    width: 260px;
    height: 120px;
    right: 150px;
    top: 120px;
    animation: modalAnimation 0.3s forwards;
    border-radius: 6px 0px 6px 6px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: $bg-message;
    color: currentColor;

    p:first-child {
      align-self: flex-start;
      margin-bottom: 20px;
    }
  }
}

// MODAL
.modalContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: none;
  align-content: center;
}

.ativo {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  align-content: center;
  
  .wrapperModal {
    animation: modalAnimation 0.3s forwards;
  }
}

@keyframes modalAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
