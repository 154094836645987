@import '../../../styles/_variaveis.scss';

.buttonContainer {
  width: 100%;
  display: inline-flex;
  height: 38px;
  background: $input-grey;
  border-radius: 0.45rem;
  cursor: pointer;
  transition: 0.1s ease-in;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 11px 13px;
    border-radius: 0.45rem;
    background: $ocean;

    svg {
      fill: $white;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    cursor: pointer;

    .text {
      padding-left: 24px;
      font-size: 11px;
      font-weight: bold;
      color: $white;
    }
  }

  &:hover {
    .button {
      background: $grey-dark;
      transition: 0.3s ease;
      border-radius: 0.45rem;
    }

    .icon {
      background: $light-ocean;
      transition: 0.3s ease;
    }
  }
}

.buttonContainer2 {
  width: 179px;
  display: inline-flex;
  height: 38px;
  background: $input-grey;
  border-radius: 0.45rem;
  cursor: pointer;

  .icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 11px 13px;
    border-radius: 0.45rem;
    background: $grey-dark;
    transition: 0.3s ease;

    svg {
      fill: $light-ocean;
    }
  }

  .button2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    cursor: pointer;

    &:first-child {
      margin-bottom: 10px;
    }

    .text {
      padding-left: 16px;
      font-size: 12px;
      font-family: $fontPadrao;
      font-weight: 700;
      color: $white;
    }
  }

  &:hover {
    .button2 {
      background: $grey-dark;
      transition: 0.3s ease;
      border-radius: 0.45rem;
    }

    .icon2 {
      background: $grey-middle;
      transition: 0.3s ease;
    }
  }
}
