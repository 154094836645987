@import '../../styles/_variaveis.scss';

.container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: $white;
  
  .title {
    font-family: $fontPadrao;
    font-weight: 700;
  }

  p {
    font-family: $fontPadrao;
  }
}