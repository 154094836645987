@import '../../styles/_variaveis.scss';

.wrapper {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 30px;
    padding: 5px 20px 5px 20px;
    background-color: $grey-dark;
    border-radius: 6px 0px 0px 6px;
    color: $grey-light;
    cursor: pointer;
  }

  .inputContainer {
    width: 100%;

    .input {
      width: 100%;
      height: 30px;
      padding: 0.8rem;
      border: 1px solid $input-focus;
      border-radius: 0px 6px 6px 0px;
      background: $input-focus;
      color: $grey-light;
      transition: 0.2s;

      &:focus,
      &:hover {
        outline: none;
        border-color: $grey-dark;
        box-shadow: 0 0 0 1px $grey-dark;
        background: $grey-light;
        color: $input-focus;
      }
    }
  }
}

.error {
  color: #f31;
  font-size: 12px;
  font-weight: 500;
  padding: 0px;
  padding-top: 4px;
}

@media screen and (max-width: 29rem) {
  .input {
    background: $input-focus;
    display: block;
    max-width: 100%;
    padding: 0.8rem;
    border-radius: $radius;
    transition: 0.2s;
    height: 30px;
  }
}
