@import '../../styles/_variaveis.scss';

.routerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.container {
  font-family: $fontPadrao;
  min-width: 56rem;
  width: 100%;
  display: flex;
  padding: 40px 20px;
  background-color: $bg-light;
  border-radius: 0.4rem;
  justify-content: space-around;
  align-items: center;
  color: $white;

  .containerWrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;

    .register {
      margin-top: 2rem;

      .linkRegister {
        display: inline-block;
        text-decoration: none;
        color: $white;
        cursor: pointer;
        align-content: center;
        padding: 7px 1.2rem;
        border: none;
        border-radius: $radius;
        background: $ocean;
        transition: 0.1s;

        &:hover,
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px #fea, 0 0 0 3px $light-ocean; /* assim criando uma bordar após a sobra*/
        }
      }
    }
  }

  .messageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 6%;
    animation: modalAnimation 0.3s forwards;

    div {
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 6px;
      padding: 24px 30px;
    }
  }

  .ContainerForm {
    width: 100%;
    min-width: 28rem;

    .backPage {
      font-family: $fontPadrao;
      display: flex;
      flex: row;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      column-gap: 8px;
      color: $grey-light;
      margin-bottom: 26px;

      &:hover {
        text-decoration: underline;

        .arrow {
          animation: back 0.6s linear infinite;
        }
      }
    }

    .wrapper {
      margin-bottom: 1rem;
      display: flex;
      width: 100%;

      .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 30px;
        padding: 5px 20px 5px 20px;
        background-color: $grey-dark;
        border-radius: 6px 0px 0px 6px;
        color: $grey-light;
        cursor: pointer;
      }

      .inputContainer {
        width: 100%;

        .input {
          width: 100%;
          height: 30px;
          padding: 0.8rem;
          border: 1px solid $input-focus;
          border-radius: 0px 6px 6px 0px;
          background: $input-focus;
          color: $grey-light;
          transition: 0.2s;

          &:focus,
          &:hover {
            outline: none;
            border-color: $grey-dark;
            box-shadow: 0 0 0 1px $grey-dark;
            background: $grey-light;
            color: $input-focus;
          }
        }
      }
    }
  }

  .title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    font-family: $fontPadrao;
    text-transform: uppercase;
    margin-top: 1.1rem;
    margin-bottom: 0.9rem;
  }

  .subTitle {
    margin-bottom: 0.6rem;
  }
}

.navigate {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .link {
    color: $ocean;
    font-family: $fontPadrao;
    text-decoration: none;
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-bottom: 3px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1.5px;
      background-color: $ocean;
      border-radius: $radius;
      scale: 0 1;
      transform-origin: left;
      transition: scale 0.25s;
    }
    
    &:hover::before {
      scale: 1;
    }
  }
}

@media screen and (max-width: 60rem) {
  /* é a mesma coisa que 960 */
  .container {
    min-width: 500px;
  }

  .log {
    display: none;
  }
}

@media screen and (max-width: 42rem) {
  /* é a mesma coisa que 672 */
  .container {
    min-width: 300px;
    height: 100vh;
    width: 100vw;
    align-items: center;
    border-radius: 0;
  }
}

@media screen and (max-width: 29rem) {
  /* é a mesma coisa que 464 */
  .routerContainer {
    background-color: aqua;
  }
  .container {
    min-width: 300px;
    display: flex;
    padding: 40px 20px;
    justify-content: space-around;
    color: $white;

    .ContainerForm {
      padding: 0 20px;
    }

    .title {
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
    }

    p {
      font-size: 14px;
    }

    .link {
      font-size: 14px;
    }
  }
}

@keyframes modalAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes back {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-12px);
  }
  100% {
    transform: translateX(0);
  }
}
