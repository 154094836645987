@import '../../../../styles/_variaveis.scss';

.title {
  font-size: 32px;
  margin-bottom: 4rem;
}

.wrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;

  .ContainerForm {
    background: transparent;
  }
}

// message
.messageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 155px;
  animation: modalAnimation 0.3s forwards;

  p {
    padding: 24px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
}

.confirmContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 155px;
  animation: modalAnimation 0.3s forwards;

  .confirm {
    padding: 24px 30px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.8);
  }

  p {
    margin-bottom: 12px;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;

    button {
      font-weight: 700;
      cursor: pointer;
      border: none;
      border-radius: $radius;
      color: $white;
      padding: 4px 8px;
      box-sizing: border-box; 
      transition: 0.1s;

      &:first-child {
        background: $red-dark;
        &:hover,
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px #fea, 0 0 0 3px $red; 
        }
      }

      &:last-child {
        background: $ocean;
        &:hover,
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px #fea, 0 0 0 3px $light-ocean; 
        }
      }
    }
  }
}

@keyframes modalAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
