$padding-horizontal-mobile: 100px;
$padding-horizontal-sm: 150px;
$padding-horizontal-md: 200px;
$padding-horizontal: 250px;

$bg: #181924;
$bg-light: #24252F;
$bg-light-scroll: #292A35;
$bg-message: rgba(41, 42, 54, .8);

$white: #F5F5F5;

$grey-dark: #383942;
$grey-middle: #43444E;
$grey-light: #727272;
$grey-textInput: #636969;

$input-focus: #191A1D;
$input-grey: #2F303A;

$ocean: #007C71;
$light-ocean: #07A497;

$red: #d73b3b;
$red-dark: #c62a2a;

$fontPadrao: 'Inter', sans-serif;
$radius: 0.4rem;