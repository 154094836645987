@import '../../styles/_variaveis.scss';

.containerPai {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $bg-light;
  border-radius: 6px;
}

.sidebar {
  display: flex;
  flex: 1;
  position: relative;
  height: 100%;
  background-color: $bg-light;
  border-radius: 4px;
  padding: 20px 20px 20px 26px;
  margin-right: 0px;
  margin-bottom: 0px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: $grey-textInput;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: $bg-light-scroll;
    -webkit-border-radius: 4px;
  }

  &::-webkit-scrollbar-button:start:decrement {
    display: none;
  }

  .toggleSide {
    position: absolute;
    top: 17.5%;
    right: -12px;
    height: auto;
    width: auto;
    cursor: pointer;

    svg {
      fill: $white;
      width: 25px;
      height: 25px;
    }
  }

  .wrapper {
    flex: 1;
    width: 298px;
    height: 85%;

    .logoContainerOpen {
      display: flex;
      flex-direction: row-reverse;
      row-gap: 12px;
      width: 100%;
      margin-bottom: 24px;
      justify-content: space-between;
      align-items: center;

      .btnMenu {
        cursor: pointer;
        color: $white;
      }

      .log {
        svg {
          &:hover {
            transform: scale(1.1);
          }
        }
        
        .title {
          display: block;
          font-size: 16px;
          font-weight: 700;
          color: $white;
          transition: 0.1s ease-in;
          margin-top: 12px;
        }
      }
    }

    .logoContainerClose {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      width: 100%;
      margin-bottom: 24px;
      align-items: flex-start;

      .btnMenu {
        position: relative;
        left: 22px;
        margin-bottom: 1px;
      }

      .log {
        position: relative;
        left: 4px;
      }
    }

    .buttonsContainer {
      height: 100%;
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 14px;

      .wrapperBtn {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        margin-bottom: 24px;
      }

      .settings {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .space {
          margin-bottom: 12px;
        }
      }
      svg {
        color: $white;
        font-size: 1.45rem;
      }
    }
  }
}
