@import '../../../styles/variaveis';

.containerPai {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: transparent;

  font-size: 12px;
  color: $white;
  font-family: $fontPadrao;

  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: $bg-light;
    border-radius: 4px;
    overflow: hidden auto;
    padding: 37px 55px 37px 33px;
    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: $grey-textInput;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: $bg-light-scroll;
      -webkit-border-radius: 4px;
    }

    &::-webkit-scrollbar-button:start:decrement {
      display: none;
    }
  }
}


